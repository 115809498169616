import Service from "../Service";
const resource = "gencompany/";

export default {
    save(cmp, requestID) {
        return Service.post(resource + "save", cmp, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    searchbyruc(parameters, requestID) {
        return Service.post(resource + "searchbyruc", parameters, {
            params: { requestID: requestID },
        });
    },

};